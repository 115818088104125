<template>
  <div v-if="title" class="left-title">
    <h2><span class="before-line"></span>{{title}}<small>{{subTitle}}</small></h2>
    <!-- 右上角插槽 -->
    <slot v-if="havaSlot" name="right"/>
  </div>
  <!-- 默认插槽，面板内容 -->
  <slot v-if="havaSlot"/>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    // 是否有插槽
    havaSlot:{
      type:Boolean,
      default: false
    },
    // 副标题
    subTitle: {
      type: String,
      default: ''
    }
  },
}
</script>

