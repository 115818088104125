<template>
  <div id="companyCss">
    <BaseBread style="margin-left: 5vh;">
      <BaseBreadItem to="/">首页</BaseBreadItem>
      <BaseBreadItem>企业概述</BaseBreadItem>
    </BaseBread>
    <div style="background-color: #fff">
      <!--  关于我们   -->
      <AboutUs/>
      <!--  业务结构   -->
      <BusinessStructure :isShow="structureShow" :id="structureIdCss"/>
      <!--  团队介绍    -->
      <CompanyIntroduce :isShow="introduceShow" :id="introduceIdCss"/>
      <!--  公司进程   -->
      <DevelopInfo :isShow="developShow" :id="developIdCss"/>
      <!--  技术资源    -->
      <TechnologyResources/>
      <!--  服务客户    -->
      <ServiceCustomer/>
      <!--  企业荣誉   -->
      <CompanyHonor style="padding-top: 50px"/>
      <!--  知识产权   -->
      <CompanyProperty/>
    </div>
  </div>
</template>
<script>
import BaseBread from "@/components/library/base-bread.vue";
import BaseBreadItem from "@/components/library/base-bread-item.vue";
import AboutUs from "@/views/company/components/about-us.vue";
import DevelopInfo from "@/views/company/components/develop-info.vue";
import CompanyIntroduce from "@/views/company/components/company-introduce.vue";
import BusinessStructure from "@/views/company/components/business-structure.vue";
import TechnologyResources from "@/views/company/components/technology-resources.vue";
import ServiceCustomer from "@/views/company/components/service-customer.vue";
import CompanyHonor from "@/views/honor/components/company-honor.vue";
import CompanyProperty from "@/views/honor/components/company-property.vue";
import {ref, watch} from "vue";


export default {
  components: {
    BusinessStructure, DevelopInfo, AboutUs,CompanyHonor, CompanyProperty,TechnologyResources,ServiceCustomer,
    CompanyIntroduce, BaseBreadItem, BaseBread
  },
  setup() {
    const introduceIdCss = ref("introduceIdCss")
    const developIdCss = ref("developIdCss")
    const structureIdCss = ref("structureIdCss")
    let introduceShow = ref(false)
    let developShow = ref(false)
    let structureShow = ref(false)
    const scrolling = () => {
      // 滚动条距文档顶部的距离
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
    }
    window.addEventListener('scroll', scrolling);
    return {
      introduceShow, structureShow, developShow,
      introduceIdCss, structureIdCss, developIdCss
    }
  },
  watch: {
    scrollTop(oldValue, newValue) {
      let introduceIdCssHeight = document.getElementById(this.introduceIdCss).offsetHeight
      if (newValue > introduceIdCssHeight) {
        this.introduceShow = true
      }
      let structureIdCssHeight = document.getElementById(this.structureIdCss).offsetHeight
      let developIdCssHeight = document.getElementById(this.developIdCss).offsetHeight
    }
  }
}

</script>
<style scoped lang="less">
#companyCss {
  background-color: #FFF;
}
</style>
