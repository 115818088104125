<template>
  <div class="company-introduce-all" :id="id">
    <div>
      <!-- 面板内容 -->
      <LeftTitle title="团队介绍"/>
      <div class="company-introduce-container">
        <div class="company-introduce-left">
          <img src="https://jianhangtech.oss-cn-hangzhou.aliyuncs.com/introduce/company-introduce.png" alt="团队介绍">
        </div>
        <div class="company-introduce-right">
          <div class="introduce-title">
            <div class="introduce-core">
              <span>
                <img src="@/assets/images/about/introduce/company-introduce1.png" alt="." style="margin-right: 5vh">
                我们的研发团队
                <img src="@/assets/images/about/introduce/company-introduce2.png" alt="." style="margin-left: 5vh">
              </span>
            </div>
          </div>
          <!--          <div v-if="isShow">-->
          <div>
            <p class="animated fadeInUp">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ i1 }}</p>
            <p class="animated fadeInLeft"><span>{{ i2.name }}</span>{{ i2.desc }}</p>
            <p class="animated fadeInRight"><span>{{ i3.name }}</span>{{ i3.desc }}</p>
            <p class="animated fadeInLeft"><span>{{ i4.name }}</span>{{ i4.desc }}</p>
            <p class="animated fadeInDown"><span>{{ i5.name }}</span>{{ i5.desc }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {i1} from "@/api/company";
import {i2} from "@/api/company";
import {i3} from "@/api/company";
import {i4} from "@/api/company";
import {i5} from "@/api/company";
import LeftTitle from "@/components/library/base-left-title.vue";

export default {
  components: {LeftTitle},
  setup() {
    return {i1, i2, i3, i4, i5}
  },
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ""
    }
  },
}
</script>

<style lang='less' scoped>
.company-introduce-all {
  width: 100%;
  height: 600px;
  padding-top: 50px;

  :first-child div {
    float: left;
    background-color: #fff;
    position: relative;
  }

  .company-introduce-container {
    width: 100%;
    height: 100%;

    .company-introduce-left {
      width: 50%;
      height: 100%;

      img {
        width: 100%;
        height: 540px;
      }

      :after {
        content: '';
        display: block;
        clear: both;
      }
    }

    .company-introduce-right {
      width: 50%;

      .introduce-title {
        padding-left: 10vh;
        height: 80px;
        width: 100%;
        position: relative;

        .introduce-core {
          height: 60px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 20px;
          width: 80%;
          box-shadow: 0 0 10px 0 #aaa;

          span {
            width: 100%;
            font-size: 20px;
            font-weight: 600;
            text-align: center;
            color: #0065B0;
          }

          img {
            width: 20px;
            height: 20px;
          }
        }
      }

      p {
        margin-left: 10px;
        margin-right: 20px;
        font-size: 17px;
        padding: 30px 10px 0 30px;
        line-height: 1.5;
        color: #4d4343;

        span {
          font-weight: 900;
          line-height: 0.5;
          color: rgb(17, 17, 17);
        }
      }
    }
  }
}
</style>
