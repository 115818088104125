<template>
  <div style="margin-top: 130px;width: 100%">
    <LeftTitle title="公司进程"/>
    <div class="company-develop-info">
      <swiper
        :slidesPerView="4"
        :spaceBetween="30"
        :freeMode="true"
        :modules="modules"
        navigation
      >
        <swiper-slide>
          <p>2014.12</p>
          <div>
            <ul>
              <li><span>·</span>公司成立</li>
              <li><span>·</span>专注物流软件开发</li>
            </ul>
          </div>
        </swiper-slide>
        <swiper-slide>
          <p>2017.5</p>
          <div>
            <ul>
              <li><span>·</span>仓库信息管理系统投入市场</li>
              <li><span>·</span>研发并承接快递分拣装置</li>
            </ul>
          </div>
        </swiper-slide>
        <swiper-slide>
          <p>2018.5</p>
          <div>
            <ul>
              <li><span>·</span>研发制造支持物流系统承建多座自动化立体仓库</li>
            </ul>
          </div>
        </swiper-slide>
        <swiper-slide>
          <p>2020.9</p>
          <div>
            <ul>
              <li><span>·</span>承建多座车间输送系统</li>
              <li><span>·</span>自动化立体仓库</li>
              <li><span>·</span>物流配送中心物流装备</li>
            </ul>
          </div>
        </swiper-slide>
        <swiper-slide>
          <p>2021.3</p>
          <div>
            <ul>
              <li><span>·</span>研究设计密集仓储系统方案</li>
              <li><span>·</span>研发四向车调度系统</li>
              <li><span>·</span>研发AGV调度系统</li>
              <li><span>·</span>通过ISO9001质量管理体系认证</li>
              <li><span>·</span>通过ISO14001环境管理体系认证</li>
              <li><span>·</span>通过ISO45001职业健康安全管理体系认证</li>
            </ul>
          </div>
        </swiper-slide>
        <swiper-slide>
          <p>2022.03</p>
          <div>
            <ul>
              <li><span>·</span>承建智能密集仓储系统项目</li>
              <li><span>·</span>承建多个智能AGV配送项目</li>
              <li><span>·</span>通过浙江省科技型中心企业认证</li>
            </ul>
          </div>
        </swiper-slide>
        <swiper-slide>
          <p>2023.12</p>
          <div>
            <ul>
              <li><span>·</span>通过国家高新技术企业认证</li>
            </ul>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>

// Import Swiper styles
import 'swiper/swiper.css';

import 'swiper/modules/free-mode.css';
import 'swiper/modules/pagination.css';
import 'swiper/modules/navigation.css';


// import required modules
import {FreeMode, Navigation} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/swiper-vue.mjs';
import LeftTitle from "@/components/library/base-left-title.vue";

export default  {
  components: {
    LeftTitle,
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [FreeMode, Navigation]
    };
  },
  props: {
    isShow: {
      type: Boolean,
      default: false
    }
  },
};
</script>
<style scoped lang="less">
.company-develop-info {
  position: relative;
  display: block;
  background-image: url('https://jianhangtech.oss-cn-hangzhou.aliyuncs.com/introduce/city-4382591_640.jpg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  --swiper-theme-color: #ffffff;

  .swiper {
    width: 100%;
    height: 300px;
  }

  .swiper-slide {
    position: relative; /* 设置为相对定位，以便可以添加绝对定位的叠加层 */
    padding-left: 50px;
    height: 300px;
    padding-top: 30px;
    /* Center slide text vertically */
    justify-content: center;
    align-items: center;
    color: white;


    p {
      font-size: 40px;
      margin-bottom: 20px;
      font-weight: bold;
    }


    li {
      list-style: none;
      font-size: 15px;

      span {
        font-weight: 900;
        line-height: 0.5;
        color: white;
        padding-right: 12px;
      }
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  /* 添加一个叠加层来增加文字的对比度 */
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4); /* 调整透明度以适应背景 */
  }
}
</style>
