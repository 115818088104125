<template>
  <div style="margin-top: 80px;width: 100%;">
    <LeftTitle title="业务结构"/>
      <div>
        <div class="company-structure-css">
          <div class="animated fadeInUp">
            <img src="https://jianhangtech.oss-cn-hangzhou.aliyuncs.com/introduce/company-structure.png" alt="业务结构">
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import LeftTitle from "@/components/library/base-left-title.vue";

export default {
  components: {LeftTitle},
  setup() {
    return {}
  },
  props: {
    isShow: {
      type: Boolean,
      default: false
    }
  },
}

</script>

<style lang='less' scoped>
.company-structure-css {
  padding-left: 10vh;
  padding-right: 10vh;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
  }
}
</style>
